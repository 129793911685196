.sayn-language-selector {
  .MuiSelect-select {
    padding: 9px 18px !important;
    background-color: white;
  }
}

._3UPJQtC0dNx9cAH3E9ONTn {
  height: 100vh;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .ant-menu-item {
    color: black !important;
  }
}

._2cdf52-1p5mQ1FP19zxLD5 {
  background-size: contain;
  height: 100vh;
  overflow-y: auto;
}

.jKYBhtqffd_GNHFELbBQS {
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf6f0;

  img {
    width: auto;
    height: 94px;
    padding: 0px;
    margin-bottom: 0px;
    object-fit: contain;
  }
}

._1KIbgbMQs2dgfOhHZmsu9l {
  background: #fff;
  padding: 0;
  position: relative;
  height: 62px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.4);
}

._2OadfiCv3DBSKCWRhwsBCh {
  padding: 12px;
}

._7PchfixYVUtsTTTO4kz_O {
  position: absolute;
  top: 17px;
  left: 70px;
}

._1s1m3ybsBAdinc8QLebcxC {
  position: absolute;
  top: 0;
  right: 25px;
  display: flex;
  align-items: center;
}

.ant-table-body {
  overflow: auto !important;
  padding-bottom: 10px;
}

.ant-menu-item .anticon {
  font-size: 13px !important;
}

.button-edit {
  color: white;
  background-color: #f1d43a;
  border-color: #f1d43a !important;
}

.button-edit:hover {
  background-color: #ffe03d !important;
  border-color: #ffe03d !important;
  color: white !important;
}

.ant-menu {
  background: rgba(255, 255, 255, 0.5) !important;
}

.question-answer-card .ant-form-item {
  margin-bottom: 5px;
}

.ant-menu-item {
  margin-top: 3.5px;
  margin-bottom: 7px;
  font-size: 13px !important;
  background-color: transparent;
}

.ant-menu-sub {
  background-color: transparent !important;
}

.logo-container {
  box-shadow: -3px 1px 7px -1px rgba(128, 128, 128, 1);
}

.logo {
  height: 30px;
}

::-webkit-scrollbar {
  border-radius: 20px;
  background-color: #f5f5f5;
  width: 8px; /* for vertical scrollbars */
  height: 8px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.37);
}

.ant-layout-sider-dark {
  z-index: 1;
  box-shadow: 1px 1px 11px 0px rgba(128, 128, 128, 1);
}

.components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

.components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-left::before {
  border-top: 1px solid #c5c5c5 !important;
}

html,
body,
#root,
.App {
  min-height: 100%;
  min-width: 100%;
}

/* CUSTOM ELEMENTS */
.sayn-text-input:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.sayn-text-input::-webkit-scrollbar {
  display: none;
}

.sayn-text-input:-ms-input-placeholder {
  color: #b9b9b9;
}

.sayn-text-input::placeholder {
  color: #b9b9b9;
}

.sayn-text-input {
  height: clamp(50px, 5vw, 70px);
  max-height: unset !important;
  border-radius: clamp(22.5px, 8.75vw, 32.5px) !important;
  padding: 16px 20px 20px 22px !important;
  font-size: clamp(16px, 3vw, 24px) !important;
  line-height: clamp(20px, 3.2vw, 30px) !important;
  border: none !important;
  color: #4d4d4d !important;
  /* hide scrollbar in IE, Edge and Firefox */
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

@media (min-width: 790px) {
  .sayn-text-input {
    padding: 14px 14px 14px 28px !important;
    margin-top: 0;
    font-size: clamp(12px, 2vw, 22px) !important;

    > * {
      font-size: clamp(12px, 2vw, 22px) !important;
    }
  }
}

/* CHECKBOX */

.sayn-checkbox {
  max-width: 100% !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
  width: 100% !important;
  padding-bottom: 0 !important;
}

.sayn-checkbox .ant-checkbox-wrapper,
.sayn-checkbox .ant-checkbox-checked:after {
  border-color: transparent !important;
}

@media (min-width: 790px) {
  .sayn-checkbox {
    margin-top: 0 !important;
    grid-gap: 10px !important;
    gap: 10px !important;
  }
}

/* CHECKBOX + RADIO */

.sayn-choice-list .ant-radio-wrapper,
.sayn-choice-list .ant-checkbox-wrapper {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 10px !important;
  aspect-ratio: unset !important;
}

.sayn-choice-list .ant-radio-wrapper > span:last-child,
.sayn-choice-list .ant-checkbox-wrapper > span:last-child {
  position: relative !important;
  white-space: normal !important;
  padding: 0 10px !important;
  font-size: 18px !important;
  color: #4d4d4d !important;
  line-height: 21px !important;
}

.sayn-choice-list .ant-radio,
.sayn-choice-list .ant-checkbox {
  position: relative !important;
  width: unset !important;
  height: unset !important;
  aspect-ratio: unset !important;
}

.sayn-choice-list .ant-radio-inner,
.sayn-choice-list .ant-checkbox-inner,
.sayn-choice-list .ant-checkbox-inner {
  background: linear-gradient(#e6e6e6, #f2f2f2) padding-box,
    linear-gradient(to top right, #c6ceda, #ffffff) border-box !important;
  width: 37px !important;
  height: 37px !important;
  border: 5px solid transparent !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}

.sayn-choice-list .ant-radio-inner::after,
.sayn-choice-list .ant-checkbox-inner::after {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  background-color: #aad2e9 !important;
  content: "" !important;
  transition: none !important;
  border: none !important;
}

.sayn-choice-list .ant-radio-checked::after,
.sayn-choice-list .ant-checkbox-checked::after {
  border-color: transparent !important;
}

@media (min-width: 790px) {
  .sayn-choice-list .ant-radio-wrapper > span:last-child,
  .sayn-choice-list .ant-checkbox-wrapper > span:last-child {
    white-space: normal !important;
    padding: 0 20px !important;
  }

  .sayn-choice-list .ant-radio-inner,
  .sayn-choice-list .ant-checkbox-inner {
    background: linear-gradient(#e6e6e6, #f2f2f2) padding-box,
      linear-gradient(to top right, #c6ceda, #ffffff) border-box !important;
    width: clamp(37px, 3.5vw, 52px) !important;
    height: clamp(37px, 3.5vw, 52px) !important;
    border: 5px solid transparent !important;
    border-radius: 50% !important;
    box-shadow: none !important;
  }

  .sayn-choice-list .ant-radio-group-item {
    position: relative !important;
    max-width: unset !important;
  }

  .sayn-choice-list .ant-radio-wrapper > span:last-child,
  .sayn-choice-list .ant-checkbox-wrapper > span:last-child {
    position: relative !important;
    color: #4d4d4d !important;
    font-size: clamp(16px, 2vw, 50px) !important;
    line-height: clamp(20px, 2.5vw, 60px) !important;
  }
}

@media (min-width: 1400px) {
  .sayn-choice-list .ant-radio-inner,
  .sayn-choice-list .ant-checkbox-inner {
    border: 7px solid transparent !important;
  }
}

/* REGISTER/LOGIN */

.sayn-register-or-login {
  text-decoration: underline;
  text-transform: uppercase;
  margin-top: 15px;
}

.auth-bottom-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
}

.auth-bottom-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  > * {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 400px) {
  .auth-bottom-row {
    flex-direction: row;
  }

  .auth-bottom-column {
    align-items: flex-end;
    margin-bottom: 0;
  }
}

@font-face {
  font-family: auxpro;
  src: url(/static/media/AauxProBla.bf5fe64b.ttf);
  font-weight: 600;
}
@font-face {
  font-family: auxpro;
  src: url(/static/media/AauxProMedOSF.adb2d3cc.ttf);
  font-weight: 500;
}
@font-face {
  font-family: auxpro;
  src: url(/static/media/AauxProRegSC.14f928db.ttf);
  font-weight: 450;
}
@font-face {
  font-family: auxpro;
  src: url(/static/media/AauxProLigOSF.51d1cb4e.ttf);
  font-weight: 400;
}
@font-face {
  font-family: auxpro;
  src: url(/static/media/AauxProThi.c4621004.ttf);
  font-weight: 300;
}

@font-face {
  font-family: myriadpro;
  src: url(/static/media/MyriadPro-Regular.17caf326.otf);
}

@font-face {
  font-family: questrial;
  src: url(/static/media/Questrial-Regular.37c8a1aa.ttf);
}

