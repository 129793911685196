:local(.layout) {
  height: 100vh;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .ant-menu-item {
    color: black !important;
  }
}

:local(.sider) {
  background-size: contain;
  height: 100vh;
  overflow-y: auto;
}

:local(.logo) {
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf6f0;

  img {
    width: auto;
    height: 94px;
    padding: 0px;
    margin-bottom: 0px;
    object-fit: contain;
  }
}

:local(.header) {
  background: #fff;
  padding: 0;
  position: relative;
  height: 62px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.4);
}

:local(.content) {
  padding: 12px;
}

:local(.left) {
  position: absolute;
  top: 17px;
  left: 70px;
}

:local(.right) {
  position: absolute;
  top: 0;
  right: 25px;
  display: flex;
  align-items: center;
}
