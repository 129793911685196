@font-face {
  font-family: auxpro;
  src: url(./AauxProBla.ttf);
  font-weight: 600;
}
@font-face {
  font-family: auxpro;
  src: url(./AauxProMedOSF.ttf);
  font-weight: 500;
}
@font-face {
  font-family: auxpro;
  src: url(./AauxProRegSC.ttf);
  font-weight: 450;
}
@font-face {
  font-family: auxpro;
  src: url(./AauxProLigOSF.ttf);
  font-weight: 400;
}
@font-face {
  font-family: auxpro;
  src: url(./AauxProThi.ttf);
  font-weight: 300;
}

@font-face {
  font-family: myriadpro;
  src: url(./MyriadPro-Regular.otf);
}

@font-face {
  font-family: questrial;
  src: url(./Questrial-Regular.ttf);
}
